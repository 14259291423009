<template>
  <!-- Begin Page Content -->
<div class="container-fluid">

<div class="row pb-1 border-bottom border-info mb-5">

  <div class="col">
        <h4 class="font-weight-lighter text-info"> <span class="fa fa-tachometer-alt"></span> Tableau De bord</h4> 
    </div>
  <div class="col">


                   <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="outline-secondary" @click="actualiserDash">
                      <i class="fa fa-sync-alt"></i>
                      <span> Actualiser</span> 
                  </b-button>
    </div>


    
</div>


    


  <!-- Content Row -->
  <div class="row">
    <!-- Earnings (Monthly) Card Example -->
    <div v-if="paiefourtrd>0 && d_gst_paiem_four==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmPaiemFour" class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal var_color_financ  mb-1">Paiements Fournisseurs </div>
              <div class="h5 mb-0  text-secondary">{{paiefourtrd}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-coins fa-2x var_color_financ"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div v-if="paieclientrd>0 && d_gst_paim_client==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmPaiemCl" class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal var_color_financ mb-1">Paiements Clients </div>
              <div class="h5 mb-0  text-secondary">{{paieclientrd}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-coins fa-2x var_color_financ"></i>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- Earnings (Monthly) Card Example -->
    <div v-if="lotsexp>0 && d_gst_art==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmLot" class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal   var_color_stock mb-1">Lots Expirés </div>
              <div class="h5 mb-0  text-secondary">{{lotsexp}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-layer-group fa-2x var_color_stock"></i>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Earnings (Monthly) Card Example -->
    <div v-if="deplacCount>0 && d_gst_deplac==true" class="col-xl-3 col-md-6 mb-4">
      <div  class="card border-left-primary shadow h-100 py-2">
        <div @click="mmDeplac"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_stock mb-1">Déplacements </div>
              <div class="h5 mb-0  text-secondary">{{deplacCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-cubes fa-2x var_color_stock"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div v-if="entreCount>0 && d_gst_entre==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmEntre"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6  font-weight-normal  var_color_stock mb-1">Entrées </div>
              <div class="h5 mb-0  text-secondary">{{entreCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-boxes fa-2x var_color_stock"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings (Monthly) Card Example -->
    <div v-if="sortiCount>0 && d_gst_sorti==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmSort"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_stock mb-1">Sorties </div>
              <div class="h5 mb-0  text-secondary">{{sortiCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-box fa-2x var_color_stock"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="livrCount>0 && d_gst_livrais==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmLivrai"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_achat mb-1">Livraisons </div>
              <div class="h5 mb-0  text-secondary">{{livrCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-file-download fa-2x var_color_achat"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="avoirCount>0 && d_gst_avoir==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmAvoir"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_achat mb-1">Avoirs </div>
              <div class="h5 mb-0 text-secondary">{{avoirCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-file-upload fa-2x var_color_achat"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="primCount>0 && get_gst_prim==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmPrim" class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  text-info mb-1">Primes Clients </div>
              <div class="h5 mb-0  text-secondary">{{primCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-inbox fa-2x text-info"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="bnchrgCount>0 && d_gst_charg" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmCharg" class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  text-info mb-1">Charges </div>
              <div class="h5 mb-0  text-secondary">{{bnchrgCount}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-people-carry fa-2x text-info"></i>
            </div>
          </div>
        </div>
      </div>
    </div>


                <!-- Earnings (Monthly) Card Example -->
    <div v-if="blcl>0 && d_gst_bl_client==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmBlCl"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_vente mb-1">BLs Clients  </div>
              <div class="h5 mb-0  text-secondary">{{blcl}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-paste fa-2x var_color_vente"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="blret>0 && d_gst_br_client==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmRetour"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_vente mb-1">Retours Clients </div>
              <div class="h5 mb-0  text-secondary">{{blret}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-file-import fa-2x var_color_vente"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

                <!-- Earnings (Monthly) Card Example -->
    <div v-if="fact>0 && d_gst_fact==true" class="col-xl-3 col-md-6 mb-4">
      <div class="card border-left-primary shadow h-100 py-2">
        <div @click="mmFact"  class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <div class="h6 font-weight-normal  var_color_vente mb-1">Factures Clients </div>
              <div class="h5 mb-0  text-secondary">{{fact}}</div>
            </div>
            <div class="col-auto">
              <i class="fas fa-file-invoice fa-2x var_color_vente"></i>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>

  <!-- Content Row -->





<!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
<!-- /.container-fluid -->


<!-- End of Main Content -->

</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
computed: {
  user_dep() {

if(this.$store.getters.get_user)
{
    return this.$store.getters.get_user.dep;
}
else
{
    return 0;
}
},


d_gst_bl_client() {
return this.$store.getters.get_gst_bl_client;
},
d_gst_br_client() {
return this.$store.getters.get_gst_br_client;
},
d_gst_fact() {
return this.$store.getters.get_gst_fact;
},

//stock

d_gst_art() {
return this.$store.getters.get_gst_art;
},
d_gst_deplac() {
return this.$store.getters.get_gst_deplac;
},

d_gst_entre() {
return this.$store.getters.get_gst_entre;
},

d_gst_sorti() {
return this.$store.getters.get_gst_sorti;
},

//achat :
d_gst_fournis() {
return this.$store.getters.get_gst_fournis;
},
d_gst_cmd() {
return this.$store.getters.get_gst_cmd;
},
d_gst_livrais() {
return this.$store.getters.get_gst_livrais;
},

d_gst_avoir() {
return this.$store.getters.get_gst_avoir;
},

//tresor :


d_gst_paiem_four() {
return this.$store.getters.get_gst_paiem_four;
},
d_gst_paim_client() {
return this.$store.getters.get_gst_paim_client;
},

//charg / decharge :

d_gst_prim() {
return this.$store.getters.get_gst_prim;
},
d_gst_charg() {
return this.$store.getters.get_gst_charg;
}
},
mounted(){
this.fetchList()
},
data () {
return {

    deplacCount: 0,
    entreCount: 0,
    sortiCount: 0,
    livrCount: 0,
    avoirCount:0,
    primCount: 0,
    bnchrgCount: 0,
    lotsexp:0,
    paiefourtrd:0,
    paieclientrd: 0,


    blcl:0,
    blret: 0,
    fact: 0,


    date_today:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
    //public trucs

    isLoading: false
}
},
methods :{
mmDeplac()
{
                        //goto first page:
                    this.$router.push({ 
                        name: 'listBnDeplac'

                    });
},
        mmEntre()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBonEntre'

            });
},
        mmSort()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBonSortie'

            });
},
        mmLivrai()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBnLivraisonFour'

            });
},
        mmAvoir()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBnAvoirFour'

            });
},

        mmPaiemFour()
{
              //goto first page:
            this.$router.push({ 
                name: 'paiemFourRetard'

            });

},
                mmPaiemCl()
{
               //goto first page:
            this.$router.push({ 
                name: 'paiemClitVendRetr'

            });
},
                mmLot()
{
              //goto first page:
            this.$router.push({ 
                name: 'listExpirRapp'

            });
},
                mmPrim()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBnPrimClient'

            });
},
                        mmCharg()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBonCharge'

            });
},
mmBlCl()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBlClient'

            });
},
mmRetour()
{
               //goto first page:
            this.$router.push({ 
                name: 'listBnRetrClients'

            });
},
mmFact()
{
               //goto first page:
            this.$router.push({ 
                name: 'listFactureClients'

            });
},

fetchList: function () {



            this.isLoading=true;
            this.$http.get('/dashBordCount/'+this.date_today+'/'+this.user_dep)
            .then((result) => {
                        this.deplacCount = result.data.dep
                        this.entreCount = result.data.ent
                        this.sortiCount = result.data.sort
                        this.livrCount = result.data.livr
                        this.avoirCount=result.data.avr
                        this.primCount = result.data.prim
                        this.bnchrgCount = result.data.bnchrg
                        this.lotsexp = result.data.lotsexp
                        this.paiefourtrd = result.data.paiefourtrd
                        this.paieclientrd = result.data.paieclientrd


                        this.blcl = result.data.blcl
                        this.blret = result.data.blret
                        this.fact = result.data.fact

                        
                        this.isLoading=false;
            })
            .catch(error=> {
                        console.log(error)
                        this.isLoading=false;
                        this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur de Chargement',
                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                        })
            });
},

actualiserDash()
{
this.fetchList()
}


},
components: {
      Loading      
}
}
</script>


<style>
.var_color_stock{
color: #35b5e4;
}

.var_color_achat{
color: #c98a8c;
}

.var_color_vente{
color: #2faaa5;
}

.var_color_financ{
color: #926e99;
}
.BgNotifIcon {
color: #ff793f;
opacity: 0.9;
}


</style>